import { Container } from 'react-bootstrap';

const Join = () => (
  <Container>
    This will be a form that people can fill out to join the psitadel. Options
    to stay anonymous. We will have to think about what it means to join the
    psitadel.
  </Container>
);

export default Join;
